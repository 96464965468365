<template>
  <div>
    <v-row>
      <v-col lg="12">
        <definicion :isAdmin="true"  @rowSelected="rowSelected($event)"  :height="100"> >
        </definicion>        
      </v-col>
      <v-col lg="12">
     <!--  <supplies :isAdmin="true"  :DedID="dedID" :OriginID="originID" :height="200"> >
        </supplies> -->
              
      </v-col>
  
      
     
    </v-row>
  </div>
</template>

<script>
//import Supplies from "@/views/TechnicalAssistance/ListSupplies/Supplies";
import Definicion from "@/views/TechnicalAssistance/MarginPrice/Definition";
export default {
  components: {
        Definicion
       // , Supplies
 },

   data() {
    return {
      parentID: 0,
      dedID:0,
      originID:0,
     
    };
  },

  methods: {
    rowSelected(rows) {
     this.dedID=rows[0].DedValue;
     this.originID=rows[0].DedID;
    },
   
  },
};
</script>
